// import { IPostTargetState, ISocialMediaConnection, PostTargetStatus } from '../../extViewmodels';

export type ViewTypes = 'SocialMediaPost';
export type ApiModelType = ViewTypes;

export interface IBaseApiTypeModel {
	_type?: ApiModelType;
}

export interface IBaseApiModel extends IBaseApiTypeModel {
	id?: string;
	/** This is truly an optional value. This is not always guaranteed to be there. */
	lastModifiedDate?: string;
}

export interface IOperationResultNoValue {
	requestId?: string;
	success?: boolean;
	systemCode?: number;
	systemMessage?: string;
}

export interface IOperationResult<T> extends IOperationResultNoValue {
	value?: T;
}

export interface IBulkOperationResult<T> extends IOperationResultNoValue {
	succeeded?: T[];
	failed?: IOperationResult<T>[];
}

export const asApiError = (e: any) => {
	let error: IOperationResultNoValue = { success: false };
	if (e) {
		if (Object.prototype.hasOwnProperty.call(e, 'systemMessage')) {
			if (Object.prototype.hasOwnProperty.call(e, 'systemCode')) {
				error = e as IOperationResultNoValue;
				error.success = false;
			} else {
				error.systemMessage = e.systemMessage;
			}
		} else if (e instanceof Error) {
			error.systemMessage = e.message;
		} else if (typeof e === 'string') {
			error.systemMessage = e;
		} else if (Object.prototype.hasOwnProperty.call(e, 'toString') && typeof e.toString === 'function') {
			error.systemMessage = e.toString();
		} else {
			error.systemMessage = 'Unexpected error.';
		}

		if (!error.systemCode && (error.systemMessage || '').toLocaleLowerCase() === 'failed to fetch') {
			error.systemCode = -1;
			error.systemMessage = 'Error connecting to Levitate.';
		}
	} else {
		error.systemMessage = 'Unexpected error.';
	}

	return error;
};

export enum HTTPMethod {
	DELETE = 'DELETE',
	GET = 'GET',
	PATCH = 'PATCH',
	POST = 'POST',
	PUT = 'PUT',
}

export interface IApiClientConfig {
	accessToken?: string;
	baseUrl: string;
}

export class ApiClient {
	protected mConfig: IApiClientConfig;

	constructor(config: IApiClientConfig) {
		this.mConfig = config;
	}

	public get baseUrl() {
		return this.mConfig.baseUrl;
	}

	public get accessToken() {
		return this.mConfig.accessToken;
	}

	public get = <T>(path: string) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.GET));
	};

	public put = <T>(path: string, body?: T) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.PUT, body));
	};

	public patch = <T>(path: string, body?: any) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.PATCH, body));
	};

	public delete = <T>(path: string) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.DELETE));
	};

	public post = <T>(path: string, body?: any) => {
		return this.executeRequest<T>(this.buildFetchRequest(path, HTTPMethod.POST, body));
	};

	protected buildFetchRequest = (path: string, method: HTTPMethod, obj: any = null) => {
		// Check for a special method called FILEPOST, which we will transform to POST but will not convert body to JSON
		const isFormDataBody = obj instanceof FormData;

		// Define headers
		const headers = new Headers();
		if (isFormDataBody) {
			// DO NOT INCLUDE A HEADER FOR FILE POSTS
		} else {
			headers.append('content-type', 'application/json; charset=utf-8');
		}
		headers.append('accept', 'application/json');
		headers.append('Pragma', 'no-cache');
		headers.append('Cache-Control', 'no-cache');
		if (this.mConfig.accessToken) {
			headers.append('Authorization', 'bearer ' + this.mConfig.accessToken);
		}
		const url = `${this.mConfig.baseUrl}/${path}`;
		const config: RequestInit = {
			headers,
			method,
			mode: 'cors',
			redirect: 'follow',
		};

		if (method.toLowerCase() !== 'get' && !!obj) {
			config.body = isFormDataBody ? obj : JSON.stringify(obj);
		}

		const request = new Request(url, config);
		return request;
	};

	protected executeRequest = async <T>(request: Request): Promise<[IOperationResult<T>, IOperationResultNoValue]> => {
		try {
			const response = await fetch(request);
			const apiResponse: IOperationResult<T> = await response.json();
			if (!apiResponse.success) {
				// @ts-ignore
				return [null, apiResponse];
			}
			// @ts-ignore
			return [apiResponse, null];
		} catch (error) {
			// @ts-ignore
			return [null, asApiError(error) as IOperationResultNoValue];
		}
	};
}

export interface IOperationResultNoValue {
	requestId?: string;
	success?: boolean;
	systemCode?: number;
	systemMessage?: string;
}

export interface IOperationResult<T> extends IOperationResultNoValue {
	value?: T;
}

export interface IBulkOperationResult<T> extends IOperationResultNoValue {
	succeeded?: T[];
	failed?: IOperationResult<T>[];
}

export interface ISocialMediaPost extends IBaseApiModel {
	anonymousAccessLink?: string;
	attachments?: IFileAttachment[];
	citation?: string;
	content: IRawRichTextContentState;
	designatedTargets?: IPostTarget[];
	dueDate?: string;
	name?: string;
	targets?: IPostTarget[];
}

export interface IPostTarget {
	provider: SocialMediaType;
	pageId?: string;
	userId?: string;
	state?: IPostTargetState;
}

export interface IPostTargetState {
	status: PostTargetStatus;
	lastAttempted?: string;
	lastConnectionState?: ISocialMediaConnection;
	attempts?: number;
	lastErrorMessage?: string;
}

export enum PostTargetStatus {
	Unknown = 'Unknown',
	Posted = 'Posted',
	Failed = 'Failed',
	Pending = 'Pending',
}

export enum SocialMediaType {
	Unknown = 'Unknown',
	Facebook = 'Facebook',
	Instagram = 'Instagram',
	LinkedIn = 'LinkedIn',
}

export interface ISocialMediaConnection {
	encryptedBlobId?: string;
	expiresAt?: string; // Date
	postTargetDisplayName?: string;
	postTargetId?: string;
	state?: SocialMediaConnectionState;
	type: SocialMediaType;
	userId?: string;
	userName?: string;
}

export const SupportedSocialMediaTypes = [
	SocialMediaType.Facebook,
	SocialMediaType.Instagram,
	SocialMediaType.LinkedIn,
];

export enum SocialMediaConnectionState {
	Connected = 'Connected',
	Disconnected = 'Disconnected',
	NoTarget = 'NoTarget',
	Unknown = 'Unknown',
}

export interface IFileAttachment {
	embedded?: boolean;
	fileName: string;
	fileSize: number;
	id: string;
	mimeType?: string;
	url?: string;
}

export interface IRawRichTextContentState {
	document?: string;
	documentVersion?: number;
	source?: string;
}
